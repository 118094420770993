import React, { useState, useEffect } from 'react';
import {Typography, Paper, Button, TextField } from '@material-ui/core';
import { Table,TableBody , TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import useStyles from './styles';
import {Backdrop,CircularProgress,} from '@mui/material';
import { commerce } from '../../../lib/commerce';


//layout of product i.e. image, desc., title, etc

const Product = ({productList, category, onAddToCart}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [qtyPerProductArr, setQtyPerProduct] = useState({});
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const handleAddToCart2 = async () => {
        setOpen(true);


        console.log(qtyPerProductArr)

        for (var key in qtyPerProductArr) {

            if( qtyPerProductArr[key] > 0   && qtyPerProductArr[key] != "" && qtyPerProductArr[key] != null ){
                onAddToCart(key, parseInt(qtyPerProductArr[key])); 
            }
            await delay(400);
        }          
        Object.keys(qtyPerProductArr).forEach(k => delete qtyPerProductArr[k])
        setQtyPerProduct({});
        setOpen(false); 

    }    

    const handleQtyChange =  (e, productId) => {
        
        var obj ={};
        for (var key in qtyPerProductArr) {
            obj[key]= qtyPerProductArr[key];
        }   
        obj[productId] = e.target.value;
        setQtyPerProduct(obj);
    }

    const handleValidation = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    return (
        <>

            <Typography variant="h4" align="center" className={classes.title}>
                {category.name}
            </Typography>
            
            <TableContainer component={Paper} style={{ border: '1px solid #4da3b4', boxShadow: '1px 1px 1px #152b3b, 2px 2px 6px #cd262a' }}>
                <Table aria-label="basic table">
                    <TableHead>
                    <TableRow  >
                        <TableCell  className={classes.tableTopRow} >Niveau</TableCell>
                        <TableCell  align="center" className={classes.tableTopRow}>Prix par élève </TableCell>
                        <TableCell align="right" className={classes.tableTopRow}>Nombre d'élèves</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {productList.map((product) => (
                            <TableRow key={product.id}>
                               
                                <TableCell component="th" scope="row" >
                                    {product.name}
                                </TableCell>

                                <TableCell align="center" >{product.price.formatted_with_symbol}</TableCell>
                                
                                <TableCell align="right" width={40}>
                                    <TextField
                                            onKeyPress={(event) => {
                                                handleValidation(event);
                                            }}

                                            type="number"
                                            InputProps={{
                                                inputProps: { 
                                                    max: 500, min: 0 
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={!qtyPerProductArr[product.id] ? "" : qtyPerProductArr[product.id]}
                                            onChange={(event) => {handleQtyChange(event, product.id);}}
                                    />                     
                                </TableCell>
                               
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                
            </TableContainer>
            <div className={classes.buttonMargin}>
           
                    <Button aria-label="Add to Cart" variant="contained" color="secondary" size="large" onClick={()=>handleAddToCart2()}>
                        Ajouter
                    </Button>
                    
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                </div>
        </>
    )
    
}

export default Product
 