import React, { useState, useEffect } from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import useStyles from './styles';
import CartItem from './CartItem/CartItem';
import {Backdrop,CircularProgress,} from '@mui/material';
import { commerce } from '../../lib/commerce';

const Cart = ({cart, onUpdateCartQty, handleRemoveFromCart, handleEmptyCart, sortedCart, resetCheckbox, handleUpdateItemQty, updateSortedCart}) => {

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const EmptyCart = () => (
        <div>
            <Typography variant="h5" gutterBottom className={classes.myMargin}> Aucun concours selectionnée.</Typography>
                            
            <Button component={Link} to="/" className={classes.myMargin} size="large" type="button" variant="contained" color="secondary" gutterBottom>
                Retour à la page d'accueil
            </Button>
        </div>
    );  

    const onRajouterClick = () => {
        resetCheckbox();
        history.push("/");
    };


    const onRecommencer = async () => {
        setLoading(true);
        await handleEmptyCart();
        setLoading(false);
    }

    useEffect( ()=>{
        if(sortedCart.length===0){
            updateSortedCart();
        }
              
    },);

    const FilledCart = () => (
        <>
            <div className={classes.toolbar}/>

            <TableContainer component={Paper} >
                <Table aria-label="collapsible table">
                    <TableHead >
                        <TableRow  >
                            <TableCell />
                            <TableCell className={classes.tableTopRow}>Concours</TableCell>
                            <TableCell align="center" className={classes.tableTopRow}>Nombre d'élèves&nbsp;(total)</TableCell>
                            <TableCell align="center" className={classes.tableTopRow}>Prix</TableCell>
                            <TableCell />
                            
                        </TableRow>
                    </TableHead>    
                    {sortedCart.map((categoryCart) => (

                        <TableBody key={categoryCart.id} >
                                <CartItem categoryCart={categoryCart} onUpdateCartQty={onUpdateCartQty} handleRemoveFromCart={handleRemoveFromCart} cart={cart} handleUpdateItemQty={handleUpdateItemQty} />
                        </TableBody>
                    ))}
                </Table>
            </TableContainer>

            <Typography variant="h5" style={{ marginTop: 50 }}>
                        Sous-total: {cart.subtotal.formatted_with_symbol}
                    </Typography>
            
            <div className={classes.cardDetails} style={{ width: 800 } } >

                <div>
                    <Button className={classes.emptyButton} size="large" type="button" variant="outlined" color="secondary" onClick={onRecommencer}>
                        Recommencer
                    </Button>
                    <Button onClick={onRajouterClick} className={classes.emptyButton} size="large" type="button" variant="contained" color="primary">
                        Rajouter 
                    </Button>
                    <Button component={Link} to="/checkout" className={classes.checkoutButton} size="large" type="button" variant="contained" color="secondary">
                        Suivant
                    </Button>
                    
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            </div>
            
            
        </>
    );
    if(!cart.line_items) return 'Loading...';

    return (
        <main >
            <Container  style={{justifyContent:'center', alignItems:'center', width:'50%'}}>
                <div className={classes.toolbar}  />
                {!cart.line_items.length ? <EmptyCart /> : <FilledCart />  }
            </Container>
        </main>
    )
}

export default Cart 
