import React from 'react'
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';

const Review = ({ checkoutToken, sortedCart }) => {
  return(
      <>
        <Typography variant="h5" gutterBottom style={{textAlign:'center'}}>Récapitulatif</Typography>
        <List disablePadding>
          {sortedCart.map((lineProduct) => (
            <ListItem style={{ padding: '10px 0' }} key={lineProduct.id}>
              <ListItemText primary={lineProduct.name} secondary={`Nombre d'inscriptions: ${lineProduct.total_qty}`} />
              <Typography variant="body2">{lineProduct.total_price}.00$</Typography>
            </ListItem>
          ))}
          <ListItem style={{ padding: '10px 0' }}>
            <ListItemText primary="Taxes" />
            <Typography variant="subtitle1" >
              incluses
            </Typography>
          </ListItem>
          <ListItem style={{ padding: '10px 0' }}>
            <ListItemText primary="Total" />
            <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
              {checkoutToken.live.subtotal.formatted_with_symbol}
            </Typography>
          </ListItem>
        </List>
        {console.log(checkoutToken)}
      </>
    )
  };

export default Review
