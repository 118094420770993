import { makeStyles } from '@material-ui/core/styles';
import { fontSize } from '@mui/system';

export default makeStyles(() => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableTopRow:{
    fontWeight: 'bold',
    color: 'purple',
    fontSize: '16px',
  },
  title:{
    color: 'darkblue',
    textDecoration: 'underline overline',
    textDecorationColor: 'red',
    textDecorationThickness: '3px',
    marginTop: '40px',
    marginBottom: '40px',
  },
  myBackgroundColor:{
    backgroundColor: 'blue',
  },
  buttonMargin:{
    marginTop: '40px',
    marginLeft: '20px',
  }
}));