import React from 'react'
import { Typography, Button, Divider } from '@material-ui/core';
import { Elements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Review from './Review';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); {/*public key*/}

const PaymentForm = ({checkoutToken, shippingData, backStep, onCaptureCheckout, nextStep, timeout, sortedCart }) => {
    
    const handleSubmit = async (event, elements, stripe) =>{
        event.preventDefault();

        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({ type: 'card', card: cardElement});
        console.log(checkoutToken.live.line_items);
        if(error) {
          console.log('[error]', error)
        }
        else{
          console.log(shippingData);
            const orderData = {
                extra_fields: {
                  extr_bO6J5aB87lEjpK: shippingData.contactPerson,
                  extr_NqKE50NO3wdgBL: shippingData.email,
                  extr_kpnNwA1qbwmXB3: shippingData.director,
                  extr_8XO3wpMdqoYAzQ: shippingData.telSchool,
                },
                customer: { firstname: shippingData.schoolName, lastname: " .", email: shippingData.email },
                shipping: {
                     name: 'Adresse École', 
                     street: shippingData.address1, 
                     town_city: shippingData.city, 
                     county_state: shippingData.shippingSubdivision, 
                     postal_zip_code: shippingData.postalCode, 
                     country: shippingData.shippingCountry 
                },
                payment: {
                  gateway: 'stripe',
                  stripe: {
                    payment_method_id: paymentMethod.id,
                  },
                },
            };
            onCaptureCheckout(checkoutToken.id, orderData);
            timeout();
            nextStep();
        
        }
    };


    return (
        <>
          <Review checkoutToken={checkoutToken} sortedCart={sortedCart} />
          <Divider />
          <Typography variant="h6" gutterBottom style={{ margin: '20px 0' }}>Méthode de paiement</Typography>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>{({ elements, stripe }) => (
              <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
                <CardElement />
                <br /> <br />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant="outlined" size="large" color="secondary" onClick={backStep}>Précédent</Button>
                  <Button type="submit" size="large" variant="contained" disabled={!stripe} color="primary">
                    Payer {checkoutToken.live.subtotal.formatted_with_symbol}
                  </Button>
                  
                </div>
              </form>
            )}
            </ElementsConsumer>
          </Elements>
        </>
    );
}

export default PaymentForm
