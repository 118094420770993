import React, { useState, useEffect } from 'react';
import { InputLabel, Select, MenuItem, Button, Grid, Typography, FormLabel,NativeSelect,CircularProgress,Backdrop   } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FormInput from './CustomTextField';
import { commerce } from '../../lib/commerce';

const AddressForm = ({checkoutToken, next}) => {
    const [shippingCountry, setShippingCountry] = useState('CA');
    const [shippingSubdivision, setShippingSubdivision] = useState('QC');
    

      const methods = useForm();
    return (
        <>
            
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((data) => next({ ...data, shippingCountry, shippingSubdivision }))}>
                    <Grid container spacing={5}>
                        <Grid item container style={{ marginTop: '20px'}}>
                            <Typography variant="h6" color="secondary" >Veuillez fournir les renseignements sur l'école participante:</Typography><br/>
                        </Grid>
                        <FormInput required name='schoolName' label='Nom de l&apos;école participante'/>
                        <FormInput name='director' label='Nom du directeur ou de la directrice' />
                        <FormInput required name='telSchool' label='Numéro de téléphone' />
                        <FormInput required name='address1' label='Adresse' />
                        <FormInput required name='city' label='Ville' />
                        <FormInput required name='postalCode' label='Code postal' />

                        <Grid item xs={12} sm={6}>
                            <InputLabel>Province</InputLabel>
                            <NativeSelect fullWidth
                                    value={shippingSubdivision}
                                    inputProps={{
                               
                                    }}
                                    onChange={(e) => setShippingSubdivision(e.target.value)}
                                >
                                    
                                    <option value={'QC'}>Québec</option>
                                    <option value={'ON'}>Ontario</option>
                                    <option value={'BC'}>Colombie-Britannique</option>
                                    <option value={'NB'}>Nouveau-Brunswick</option>
                                    <option value={"MB"}>Manitoba</option>
                                    <option value={'NS'}>Nouvelle-Écosse</option>
                                    <option value={'SK'}>Saskatchewan</option>
                                    <option value={'NL'}>Terre-Neuve-et-Labrador</option>
                                    <option value={'PE'}>Île-du-Prince-Édouard</option>
                                    <option value={'NU'}>Nunavut</option>
                                    <option value={'YT'}>Yukon</option>
                                    <option value={'NT'}>Territoires du Nord-Ouest</option>
                                    <option value={'AB'}>Alberta</option>

                                </NativeSelect>
                        </Grid>

                        

                        <Grid item xs={12} sm={6}>
                            <InputLabel>Pays</InputLabel>
                            <NativeSelect fullWidth
                                    value={shippingCountry}
                                    inputProps={{
                                   
                                    }}
                                    onChange={(e) => setShippingCountry(e.target.value)}
                                >
                              
                                    <option value={'CA'}>Canada</option>
                                </NativeSelect>
                        </Grid>
                        <Grid item container  style={{ marginTop: '30px'}}>
                            <Typography variant="h6" color="secondary">Veuillez fournir les renseignements sur la personne responsable:</Typography><br/>
                        </Grid>
                        <FormInput required name='contactPerson' label='Nom de la personne responsable' />
                        <FormInput required name='email' label='Courriel de la personne responsable' />

                    </Grid>
                    <br/><br/><br/><br/>
                    <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button component={Link} variant="outlined" to="/sommaire" color="secondary" >Retourner au sommaire</Button>
                        <Button type="submit" variant="contained" color="secondary">Suivant</Button>

                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default AddressForm
