import React from "react";
import {Grid} from '@material-ui/core';
import ProductShow from './ProductShow/ProductShow';
import useStyles from './styles';

const SelectPage = ({categories, selected, onChangeCheckbox}) => {
    
    const classes = useStyles();

    return (
        <main className={classes.content} >
            <div className = {classes.toolbar} /> 
            <Grid container justifyContent="center" spacing={5} >

                {categories.map((category)=>(

                    <Grid item key={category.id} xs={12} sm={6} md={4} lg ={3}>
                        <ProductShow category={category} onChangeCheckbox={onChangeCheckbox} selected={selected}/> 
                    </Grid>
                        
                ))}
                    
            </Grid>  
        </main>   
    )  
}

export default SelectPage;