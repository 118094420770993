import React, { useState, useEffect } from 'react';
import { CssBaseline, Paper, Stepper, Step, StepLabel, Typography, CircularProgress, Divider, Button } from '@material-ui/core';
import useStyles from './styles';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import { commerce } from '../../../lib/commerce';
import {Link, useHistory} from 'react-router-dom';

const steps = ['Renseignements', 'Paiement'];

const Checkout = ({cart, order, onCaptureCheckout, error, sortedCart }) => {

    const [activeStep, setActiveStep] = useState(0);
    const [checkoutToken, setCheckoutToken] = useState(null);
    const [shippingData, setShippingData] = useState({});
    const [isFinished, setIsFinished] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    useEffect(()=>{

        if (cart.id) {
            console.log(cart);
            const generateToken =async () => {
                try{
                    const token = await commerce.checkout.generateToken(cart.id, {type: 'cart'});
                    console.log(token);
                    setCheckoutToken(token);
                }catch (error){
                    if(checkoutToken == null){
                        console.log(error);
                        history.push("/");
                    }
                }
            };
            generateToken();
        }
    }, [cart]);


    const timeout = () => {
        setTimeout(()=> {
            setIsFinished(true);
        }, 10000);
    }

   
    let Confirmation = () => order.customer ? (
        <>
            <div>
                <Typography variant="h5" color="secondary" style={{ textAlign: 'center', marginBottom: '20px' }}>Merci de participer aux Concours RM! </Typography>
                <Divider className={classes.divided}></Divider>
                <Typography variant="h6" style={{ textAlign: 'center', marginTop: '15px' }}>Numéro de référence de la candidature: {order.customer_reference}</Typography>
                <br/>
                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '50px' }}>Un courriel de confirmation vous sera envoyé sous peu. Vérifiez votre dossier 'spam' si le courriel ne se retrouve pas dans votre boîte de réception.</Typography>
                <Button component={Link} to="/" variant="contained" color="primary" type="button">Retour à la page d'accueil</Button>
            </div>
        </>
    ): isFinished ? (
        <>
        <Typography variant="h5">Une erreur s'est produite. Le paiement n'a pas pu être completé. Veuillez reéssayer à nouveau. Assurez-vous de fournir une adresse e-mail valide. </Typography>
        <br/>
        <Typography variant="body2">Erreur: {error}</Typography>
        <br/>
        <Button component={Link} to="/" variant="contained" type="button" color="secondary"> Retour à la page d'accueil</Button>

        </>
    ):(
        <div className={classes.spinner}>
            <CircularProgress/>
        </div>
    );
    
    if(error){
           
    }

    const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep +1);
    const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep -1);

    const next = (data) => {
        setShippingData(data);
        nextStep();
    };

    const Form = () => (activeStep === 0 
        ? <AddressForm checkoutToken={checkoutToken} next={next} />
        : <PaymentForm shippingData={shippingData} checkoutToken={checkoutToken} backStep={backStep} onCaptureCheckout={onCaptureCheckout} nextStep={nextStep} timeout={timeout} sortedCart = {sortedCart}/>
    );

    return (
        <>
            <CssBaseline />
            <div className={classes.toolbar} />
            <main className={classes.layout} >
                <Paper className={classes.paper} elevation={6}>
                    <Typography variant="h4" align="center">Renseignements</Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map((step) => (
                            <Step key={step}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? <Confirmation /> : checkoutToken && <Form />}
                </Paper>
            </main>
        </>

    );
};

export default Checkout;
