import React, { useState, useEffect } from 'react';
import { Card, CardMedia, CardContent, CardActions, Typography, FormGroup, Button } from '@material-ui/core';
import useStyles from './styles';
import scriptura from '../../../assets/scriptura.jpg';
import lectura from '../../../assets/lectura.jpg';
import rhetorica from '../../../assets/rhetorica.jpg';


//layout of product i.e. image, desc., title, etc

const ProductShow = ({category, selected, onChangeCheckbox}) => {
    const classes = useStyles();

    return (

    <Card sx={{ maxWidth: 305 }} style={{ boxShadow: '5px 5px 1px #152b3b, 8px 8px 2px #cd262a'}}>
        
        <CardMedia className={classes.media}  title={category.name} image={category.name==="Lectura" ?  lectura : category.name==="Scriptura" ? scriptura : rhetorica}/>

        <CardContent>

          <Typography gutterBottom variant="h4" component="div">
            {category.name}         
            <Button className={classes.learnMore} size="small" color="primary" variant="outlined" 
                href={category.id === 'cat_DWy4oGmAKl6Jx2' ? "https://concoursrm.com/rhetorica":`https://concoursrm.com/${category.name}`}> 
                En savoir plus
            </Button>
          </Typography>
          
          <Typography dangerouslySetInnerHTML={{__html: category.description}} variant='h6' color='textSecondary'/> 
        </CardContent>

        <CardActions>
            <FormGroup>
                
                <label> 
                    <input
                        type="checkbox"
                        value={
                            category.id === 'cat_zkK6oLpaOoXn0Q' ? selected[0]: 
                            category.id === 'cat_DWy4oGmAKl6Jx2' ? selected[1]: 
                            category.id === 'cat_RyWOwmMK9wnEa2' ? selected[2]: false}
                       
                        onClick={e=>onChangeCheckbox(e, category.id)}
                    /> <span className={classes.spanMargin}>cocher pour inclure</span>
                </label>
                
            </FormGroup> 

        </CardActions>
        
    </Card>
    
    )
}

export default ProductShow
 