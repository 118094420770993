import React, {useState, useEffect} from 'react';
import {commerce} from './lib/commerce';
import {SelectedProducts, Navbar, Cart, Checkout, SelectPage} from './components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
//v3
const App = () => {
    
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    const [productsByCategory, setProductsByCategory] = useState([]);
    const [cart, setCart] = useState({});
    const [order, setOrder] =useState({});
    const [errorMessage, setErrorMessage] =useState('');

    const [selectedProducts, setSelectedProducts] = useState([false,false,false]);

    const scriptura_id = "cat_zkK6oLpaOoXn0Q";
    const lectura_id = "cat_RyWOwmMK9wnEa2";
    const rhetorica_id = "cat_DWy4oGmAKl6Jx2";


    const [sortedCart, setSortedCart] = useState([]);

  
    const updateSortedCart = async () => {
        
        const newCart = await commerce.cart.retrieve(cart.id);

        if(newCart.line_items.length > 0){

            var categoryIdNameObj = {};
            for(var k =0; k<categories.length; k++){
                categoryIdNameObj[categories[k].id] = categories[k].name;
            }

            var cartObj_temp = [];

            for( const [categoryId, categoryName] of Object.entries(categoryIdNameObj) ){

                var totalPrice = 0;
                var totalQty = 0;
                var itemList = [];

                for(var i = 0; i< newCart.total_unique_items; i++){
                    if( newCart.line_items[i].sku.startsWith(categoryName.charAt(0))) {

                        totalPrice +=  parseInt(newCart.line_items[i].line_total.raw);
                        totalQty += parseInt(newCart.line_items[i].quantity);
                    {/*itemList[cart.line_items[i].id] =  [cart.line_items[i].selected_options[0].option_name, cart.line_items[i].quantity];*/}
                        itemList.push({id: newCart.line_items[i].id, niveau: newCart.line_items[i].product_name , quantity: newCart.line_items[i].quantity, subtotal:newCart.line_items[i].line_total }) 
                    }

                }
                
                if(totalQty > 0){
                    cartObj_temp.push({id:categoryId, name: categoryName,  total_price: totalPrice,  total_qty: totalQty,  item_list: itemList});
                }
                        
            }
            setSortedCart(cartObj_temp);
        }
    };

    const fetchProducts = async() => {
        
        const dataS = await commerce.products.list({category_id:scriptura_id});
        const dataL = await commerce.products.list({category_id:lectura_id});
        const dataR = await commerce.products.list({category_id:rhetorica_id});

        setProductsByCategory([dataS.data,dataL.data,dataR.data])
        setProducts(dataR);
    };

    const fetchCategories = async() => {
        const {data} = await commerce.categories.list();
        console.log(data);
        setCategories(data);
    };
    
    const fetchCart = async() => {
        setCart( await commerce.cart.retrieve());
    };

    const handleAddToCart = async(productId, quantity) => {
        const item = await commerce.cart.add(productId, quantity);
        setCart(item.cart);
    };

    const handleUpdateCartQty = async(productId, quantity) => {
        const response = await commerce.cart.update(productId, {quantity});
        setCart(response.cart);

    };

    const handleUpdateItemQty = async(itemId, quantity) => {

        if( quantity === 0){
            const {cart} = await commerce.cart.remove(itemId);
            setCart(cart); 
        }
        else{
            const response = await commerce.cart.update(itemId, {quantity});
            setCart(response.cart);
        }

        updateSortedCart();
        
    };

    const handleRemoveFromCart = async(itemId) => {
        const response = await commerce.cart.remove(itemId);
        setCart(response.cart);
        updateSortedCart();
    };

    const handleEmptyCart = async() => {
        const {cart} = await commerce.cart.empty();
        setCart(cart);
        var temp = [false,false,false];
        setSelectedProducts(temp);

    };

    const refreshCart = async () => {
        const newCart = await commerce.cart.refresh();
        setCart(newCart);
    }

    const handleCaptureCheckout = async(checkoutTokenId, newOrder) => {
        try{
            console.log( await commerce.checkout.getToken(checkoutTokenId));
            console.log( await commerce.cart.retrieve(cart.id));

            const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);
            console.log(incomingOrder);
            setOrder(incomingOrder);
            refreshCart();
        }
        catch(error){
            setErrorMessage(error.data.error.message);
        }

    };

    const handleCheckbox = async (event, catId) => {
        
        const checkBox =  event.target.checked;
        console.log(checkBox+" : "+catId);
        var temp = [selectedProducts[0],selectedProducts[1],selectedProducts[2]]
        console.log(temp);

        if(catId === scriptura_id){
            
            temp[0]=checkBox;
        }
        
        else if(catId === lectura_id){
            temp[1]=checkBox;
        }

        else if(catId === rhetorica_id){
            temp[2]=checkBox;
        }
        else {
            console.log("unknown product or request not found");
        }
        setSelectedProducts(temp);

    }

    const resetCheckbox = async () => {
        setSelectedProducts([false,false,false]);
    }

    useEffect( () => {
        fetchProducts();
        fetchCategories();
        fetchCart();
        updateSortedCart();
    }, []);

    

    return (
        <Router>
            <div>
                <Navbar totalItems={cart.total_items} selected={selectedProducts} resetCheckbox={resetCheckbox} updateSortedCart={updateSortedCart}/>
                <Switch>
                    
                   
                    <Route exact path="/">
                        <SelectPage categories ={categories} onChangeCheckbox ={handleCheckbox} selected={selectedProducts} /> 
                    </Route>
                    
                    <Route exact path="/selection">
                        <SelectedProducts selected = {selectedProducts} onAddToCart ={handleAddToCart} fetchCart={fetchCart} productsByCategory={productsByCategory}  handleUpdateCartQty={handleUpdateCartQty}/> 
                    </Route>

                    <Route exact path="/sommaire">
                        <Cart 
                            updateSortedCart={updateSortedCart}
                            handleUpdateItemQty={handleUpdateItemQty}
                            resetCheckbox={resetCheckbox}
                            sortedCart = {sortedCart}
                            cart={cart}
                            handleUpdateCartQty={handleUpdateCartQty}
                            handleRemoveFromCart={handleRemoveFromCart}
                            handleEmptyCart={handleEmptyCart}  
                        />
                        
                    </Route>

                    <Route exact path="/checkout">
                        <Checkout 
                            sortedCart = {sortedCart}
                            cart ={cart}
                            order={order}
                            onCaptureCheckout = {handleCaptureCheckout}
                            error = {errorMessage}
                        />
                    </Route>

                </Switch>

            </div>
        </Router>
        
    );
}

export default App
