import React, { useState } from 'react';
import { AppBar, Toolbar, Badge, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import { ArrowForward, Assignment } from '@material-ui/icons';
import useStyles from './styles';
import {Link, useLocation} from 'react-router-dom';
import { useHistory } from "react-router-dom";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Navbar=({totalItems, selected, resetCheckbox, updateSortedCart}) => {
    
    const classes = useStyles();
    const location= useLocation();
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const handleClickOpen = () => {

        if( (!selected[0] && !selected[1] && !selected[2]) ){

            setOpen(true);
        }
        else{
            history.push("/selection");
        }
        
    };

    const handleClickOpen2 = () => {
        
       if (totalItems > 0){
        history.push("/sommaire");
        updateSortedCart();
        resetCheckbox();
       }
       else{
        setOpen2(true);
        
       }
        
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const handleGoToHome = () => {
        history.push("/");
        resetCheckbox();
    }

    const handleGoToSommaire = () => {
        history.push("/sommaire");
        resetCheckbox();
        updateSortedCart();
    }
    
    
    return (
        <>
            <AppBar position="fixed" className={classes.appBar} color="inherit" >

                <Toolbar>

                    <Typography onClick={handleGoToHome} variant="h6" className={classes.title} color="inherit">
                        <img src="https://concoursrm.com/wp-content/uploads/2021/06/logoRM.png" alt="Les Concours RM" height="25px" className={classes.image} /> Les Concours RM
                    </Typography>

                    <div className={classes.grow} />

                    {totalItems && location.pathname === '/' ?  
                    <div style={{marginRight: '20px' }}> 
                            <Button size="large" variant="contained" aria-label="Show cart items" color="secondary" onClick={handleGoToSommaire}>            
                                Sommaire  <Assignment style={{marginLeft: '10px' }}/> 
                            </Button> 
                    </div>: <div/>}
                   
                   

                    {location.pathname === '/' && (

                        
                        <div className={classes.button}>

                            <Button size="large" variant="contained" aria-label="Show cart items" color="secondary" onClick={handleClickOpen}>            
                                Suivant <ArrowForward /> 
                            </Button> 

                            
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-describedby="alert-dialog-slide-description"
                                
                            >   
                                <DialogTitle>{"Aucun concours sélectionné."}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Veuilez sélectionner un ou plusieurs concours en cochant la case appropriée.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant='contained' color='primary' onClick={handleClose}>Retour</Button>
                                </DialogActions>


                            </Dialog>
                            
                        </div> 
                        
                    )}

                    {location.pathname === '/selection' && (

                        <div className={classes.button}>

                            <Badge badgeContent={totalItems} max={999} color="primary" ></Badge>  
                            <Button onClick={handleClickOpen2} aria-label="Show cart items" size="large" color="secondary" variant="contained">            
                                sommaire <ArrowForward /> 
                            </Button>     

                            <Dialog
                                open={open2}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-describedby="alert-dialog-slide-insuffisant"
                            >
                                <DialogTitle>{"Nombre d'élèves insuffisant"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="nombre-insuffisant">
                                        Veuilez indiquer le nombre d'élèves à inscrire pour chaque concours et ajouter.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant='contained' color='primary' onClick={handleClose2}>Retour</Button>
                                </DialogActions>
                            </Dialog>

                        </div> 

                    )}


                    {location.pathname === '/cart' && (

                        <div className={classes.button}>

                            <Button component={Link} to="/checkout" aria-label="Show cart items" size="large" color="secondary" variant="contained">            
                                suivant <ArrowForward /> 
                            </Button>     

                            <Dialog
                                open={open2}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-describedby="alert-dialog-slide-insuffisant"
                            >
                                <DialogTitle>{"Nombre d'élèves insuffisant"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="nombre-insuffisant">
                                        Veuilez indiquer le nombre d'élèves à inscrire pour chaque concours et ajouter.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant='contained' color='primary' onClick={handleClose2}>Retour</Button>
                                </DialogActions>
                            </Dialog>

                        </div> 

                    )}

                </Toolbar>
            </AppBar>
        </>
    )
}

export default Navbar
