import React, { useState, useEffect } from 'react';
import {Grid} from '@material-ui/core';
import Product from './Product/Product';
import useStyles from './styles';
import {Backdrop,CircularProgress,} from '@mui/material';
import {Link, useHistory} from 'react-router-dom';


const SelectedProducts = ({selected, onAddToCart, productsByCategory, handleUpdateCartQty}) => {

    const classes = useStyles();
    const [selectedCategory, setSelectedCategory] = useState([]); 
    const scriptura_id = "cat_zkK6oLpaOoXn0Q";
    const lectura_id = "cat_DWy4oGmAKl6Jx2";
    const rhetorica_id = "cat_RyWOwmMK9wnEa2";
    const [loading, setLoading ] = useState(true); 
    const history = useHistory();

    
  
    const fetchSelectedProducts = async(selectedArr) => {

        var productListByCategory = [];
        if(selectedArr[0]){
            
            const scripObj = {id: scriptura_id, name: "Scriptura", products:productsByCategory[0]}
            productListByCategory.push(scripObj);
        }
        
        if(selectedArr[1]){

            const lectObj ={id: lectura_id, name: "Lectura", products:productsByCategory[1]};
            productListByCategory.push(lectObj);
        }

        if(selectedArr[2]){

            const rhetObj ={id: rhetorica_id, name: "Rhétorica", products:productsByCategory[2]};
            productListByCategory.push(rhetObj);
        }

        console.log(productListByCategory);
        setSelectedCategory(productListByCategory);
        setLoading(false);
        
    };

    useEffect(()=>{
        
        if( !selected[0] && !selected[1] && !selected[2]){
            history.push("/");
        }
        else{

            fetchSelectedProducts(selected);
        }
        
    }, []);
    
  

    return (
        <main className={classes.content} >

            <div>
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                </Backdrop>

                <div className = {classes.toolbar}/> 
                
                <Grid container justifyContent="center" spacing={5}>
                    {selectedCategory.map((category)=>(
                    
                        <Grid item key={category.id} xs={12} sm={6} md={5} lg ={4} >
                            <Product category={category} productList={category.products} onAddToCart={onAddToCart} handleUpdateCartQty={handleUpdateCartQty}/> 
                        </Grid>
                            
                    ))}
                        
                </Grid>
            </div>

           
        </main>
    )

   
}

export default SelectedProducts;