import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  media: {
    height: 260,
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cartActions: {
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  rowBackgroundColor: {
    backgroundColor: 'rgba(100, 52, 150, 0.1)'
  },
  subRowsTitle: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
}));