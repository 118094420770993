import React, { useState, useEffect } from 'react';
import {Typography, Collapse, IconButton, Table, TableCell, TableHead, TableRow, TextField, TableBody} from '@material-ui/core';
import { Box } from '@mui/system';
import useStyles from './styles';
import {Backdrop,CircularProgress,} from '@mui/material';

import {ArrowCircleUpTwoTone, ArrowCircleDownTwoTone, ClearTwoTone, Check} from '@mui/icons-material'

const CartItem = ({categoryCart, onUpdateCartQty, handleRemoveFromCart, cart, handleUpdateItemQty}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false); 
 
    const [niveauQty, setNiveauQty] = useState({});
  
    const handleUpdateCartQty = (lineItemId, newQuantity) => onUpdateCartQty(lineItemId, newQuantity);
    
    const onRemoveFromCart = (lineItemId) => {
        handleRemoveFromCart(lineItemId,"") ;
    }


    const onRemoveFromCartAll = async (CategorylineItems) => {
        for(var i = 0; i< CategorylineItems.item_list.length; i++) {
            await handleRemoveFromCart(CategorylineItems.item_list[i].id);
        } 
    }

    const handleQtyChangeParNiveau = async (e, itemId) => {
        niveauQty[itemId] = e.target.value;
        setNiveauQty(niveauQty);
    }

    const submitQtyChangeParNiveau = async (itemId) => {
        handleUpdateItemQty(itemId, niveauQty[itemId]);
        
    }

    const handleValidation = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    return (

    <>
        
        <TableRow>
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <ArrowCircleUpTwoTone /> : <ArrowCircleDownTwoTone />}
                </IconButton>
            </TableCell>
            <TableCell >{categoryCart.name}</TableCell>
            <TableCell align="center">{categoryCart.total_qty}</TableCell>
            <TableCell align="center">{categoryCart.total_price}.00$</TableCell>
            <TableCell align="center">
                <IconButton color="secondary" size="small" onClick={() => onRemoveFromCartAll(categoryCart)}>
                    <ClearTwoTone/>
                </IconButton>
               
            </TableCell>
        </TableRow>
        
        <TableRow className={classes.rowBackgroundColor} >
            <TableCell style={{ paddingBottom: 5, paddingTop: 5 }} colSpan={6}>
                <Collapse in={open} timeout="auto" >
                    <Box sx={{ margin: 1 }}>

                        <Typography variant="h5" gutterBottom component="div" >
                            Détails
                        </Typography>

                        <Table size="small" aria-label="purchases" >
                            <TableHead>

                                <TableRow>
                                    <TableCell width={260} className={classes.subRowsTitle}>Niveau séléctionné</TableCell>
                                    <TableCell width={100} align="left" className={classes.subRowsTitle}>Nombre d'élève</TableCell>
                                    <TableCell align="left"></TableCell>
                                    
                                </TableRow>
                                
                            </TableHead>
                            <TableBody>
                                {categoryCart.item_list.map((item) => (

                                    <TableRow key={item.id} >

                                        <TableCell >{item.niveau}</TableCell>
                                        <TableCell align="left"  >
                                            
                                            <TextField
                                                    
                                                    size = "small"
                                                    onKeyPress={(event) => {
                                                        handleValidation(event);
                                                    }}

                                                    defaultValue="0"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: { 
                                                            max: 200, min: 0,
                                                            style: { textAlign: 'center' } 
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    defaultValue={item.quantity}
                                                    value={niveauQty[item.id]}
                                                    onChange={(event) => {handleQtyChangeParNiveau(event, item.id)}}
                                            />                 

                                        </TableCell>

                                        <TableCell align="left">
                                            <IconButton style={{color: 'green'}} size="small" onClick={() => submitQtyChangeParNiveau(item.id)}>
                                                <Check/>
                                            </IconButton>

                                            <IconButton color="secondary" size="small" onClick={() => onRemoveFromCart(item.id, categoryCart)}>
                                                <ClearTwoTone/>
                                            </IconButton>

                                        </TableCell>    

                                        
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>

    </>
                    
    );
};

export default CartItem;
